import React from "react";
import Layout from "../components/layout/Layout";

import "./index.css";
import Hero from "../components/layout/Hero";
import SEO from "../components/seo/SEO";
import useAvailableCourses from "../utils/useAvailableCourses";

export default function IlmoittauduKurssille() {
  const availableCourses = useAvailableCourses();

  return (
    <>
      <SEO title="Ilmoittaudu kurssille" description="" />
      <Layout>
        <Hero image="dev_focus">Ilmoittaudu kurssille</Hero>

        <section className="my-5 lg:w-8/12">
          <p>
            Alla olevalla lomakkeella voit ilmoittautua Relevantumin julkisille
            kursseille. Käsittelemme ilmoittautumiset mahdollisimman pian ja
            olemme yhteydessä sinuun.
          </p>
          <form
            name="ilmoittautuminen"
            action="/kiitos"
            method="POST"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="ilmoittautuminen" />

            <div className="flex flex-col">
              <h3 className="font-bold mb-2 mt-4 block">Valitse kurssit</h3>
              <div className="flex flex-col mb-4">
                {availableCourses.map(course => {
                  const date = course.date;

                  const dateStr = [date.startDate, date.endDate]
                    .filter(v => v)
                    .join("–");

                  const value = [
                    course.title,
                    dateStr,
                    date.place,
                    `${course.price} € + alv`
                  ].join(" | ");

                  return (
                    <label
                      className="mb-2 flex items-center gap-2"
                      key={course.title + dateStr}
                    >
                      <input
                        type="checkbox"
                        name="kurssit[]"
                        value={value}
                        className="w-4 h-4 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />{" "}
                      <span>
                        <span className="font-bold">{course.title}</span>{" "}
                        {dateStr}, {date.place}, {course.price} € + alv
                      </span>
                    </label>
                  );
                })}
              </div>
              <h3 className="font-bold mb-2 mt-6 block">Osallistujan tiedot</h3>
              <div className="block md:flex mb-4 gap-4">
                <div className="mb-4 md:mb-0 flex-1 flex flex-col">
                  <label htmlFor="etunimi" className="font-bold mb-2 block">
                    Etunimi
                  </label>{" "}
                  <input
                    type="text"
                    name="etunimi"
                    required
                    className="px-4 py-3 border-gray-400 border-solid border rounded-lg"
                  />
                </div>
                <div className="flex-1 flex flex-col">
                  <label htmlFor="sukunimi" className="font-bold mb-2 block">
                    Sukunimi
                  </label>{" "}
                  <input
                    type="text"
                    name="sukunimi"
                    required
                    className="px-4 py-3 border-gray-400 border-solid border rounded-lg"
                  />
                </div>
              </div>
              <div className="flex flex-col mb-4">
                <label htmlFor="sähköposti" className="font-bold mb-2 block">
                  Sähköposti
                </label>{" "}
                <input
                  type="email"
                  name="email"
                  required
                  className="px-4 py-3 border-gray-400 border-solid border rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label htmlFor="puhelin" className="font-bold mb-2 block">
                  Puhelin
                </label>{" "}
                <input
                  type="text"
                  name="puhelin"
                  required
                  className="px-4 py-3 border-gray-400 border-solid border rounded-lg"
                />
              </div>

              <h3 className="font-bold mb-2 mt-6 block">Laskutustiedot</h3>
              <div className="flex flex-col mb-4">
                <label htmlFor="yritys" className="font-bold mb-2 block">
                  Yritys
                </label>{" "}
                <input
                  type="text"
                  name="yritys"
                  required
                  className="px-4 py-3 border-gray-400 border-solid border rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label
                  htmlFor="laskutustiedot"
                  className="font-bold mb-2 block"
                >
                  Verkkolaskutustiedot
                </label>{" "}
                <textarea
                  name="laskutustiedot"
                  required
                  className="px-4 py-3 border-gray-400 border-solid border rounded-lg h-48"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label htmlFor="laskutusviite" className="font-bold mb-2 block">
                  Laskutusviite
                </label>{" "}
                <input
                  type="text"
                  name="laskutusviite"
                  className="px-4 py-3 border-gray-400 border-solid border rounded-lg"
                />
              </div>
              <div className=" mt-4">
                <button
                  type="submit"
                  className="bg-indigo-800 text-white px-4 py-3 rounded-xl font-bold"
                >
                  Lähetä
                </button>
              </div>
            </div>
          </form>
        </section>
      </Layout>
    </>
  );
}
