import { graphql, useStaticQuery } from "gatsby";

function useAvailableCourses() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fields: { collection: { eq: "courses" } } }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              path
              title
              price
              courseDates {
                startDate
                endDate
                place
              }
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `);

  let availableCourses;
  if (data && data.allMarkdownRemark && data.allMarkdownRemark.edges) {
    const edges = data.allMarkdownRemark.edges;
    availableCourses = edges
      .flatMap(({ node }) => {
        const course = node.frontmatter;

        if (!course.courseDates) {
          return [];
        }

        return course.courseDates.map(cd => {
          return {
            title: course.title,
            price: course.price,
            date: cd
          };
        });
      })
      .sort((a, b) => {
        if (a.title > b.title) {
          return 1;
        }
        return -1;
      });
  } else {
    availableCourses = [];
  }

  return availableCourses;
}

export default useAvailableCourses;
